@import '~antd/dist/antd.less';
@import '~leaflet/dist/leaflet.css';
@import '~react-leaflet-markercluster/dist/styles.min.css';

.App {
  text-align: center;
}

.ant-popover-arrow {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.leaflet-control-attribution a svg {
  display: none !important;
}

.leaflet-popup-close-button {
  display: none !important;
}

.leaflet-popup-content {
  margin: 0px !important;
  width: 300px !important;
}

.leaflet-popup-content-wrapper {
  padding: 0px !important;
  border-radius: 0px !important;
}
@primary-color: #1DA57A;